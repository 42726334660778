import request from '@/utils/admin/request'

export function addPlan(data) {
    return request({
        url: 'charge-account/purchase',
        method: 'post',
        data: data
    })
}

export function getAccountUserKeyList(params) {
    return request({
        url: 'charge-account/purchase/get-factory-index-list',
        method: 'get',
        params: params
    });
}

export function getPurchaseList(params) {
    return request({
        url: 'charge-account/purchase',
        method: 'get',
        params: params
    });
}

export function editSku(data) {
    return request({
        url: 'charge-account/purchase/edit-sku',
        method: 'post',
        data: data
    })
}

export function addSku(data) {
    return request({
        url: 'charge-account/purchase/add-sku',
        method: 'post',
        data: data
    })
}

export function getPurchasePlan(uuid) {
    return request({
        url: 'charge-account/purchase/plan/' + uuid,
        method: 'get',
    });
}

export function addDelivery(data) {
    return request({
        url: 'charge-account/purchase/add-delivery',
        method: 'post',
        data: data
    })
}

export function getDelivery(data) {
    return request({
        url: 'charge-account/purchase/get-delivery',
        method: 'get',
        params: data
    })
}

export function deleteDelivery(data) {
    return request({
        url: 'charge-account/purchase/delivery',
        method: 'delete',
        data: data
    })
}

export function addFactory(data) {
    return request({
        url: 'charge-account/purchase/factory',
        method: 'post',
        data: data
    })
}

export function deleteP(data) {
    return request({
        url: 'charge-account/purchase',
        method: 'delete',
        data: data
    })
}

export function placeP(data) {
    return request({
        url: 'charge-account/purchase/place',
        method: 'post',
        data: data
    })
}

export function factoryPay(data) {
    return request({
        url: 'charge-account/purchase/factory/pay',
        method: 'post',
        data: data
    })
}

export function payCarriage(data) {
    return request({
        url: 'charge-account/purchase/pay-carriage',
        method: 'post',
        data: data
    })
}

export function pagePrice(data) {
    return request({
        url: 'charge-account/purchase/price',
        method: 'get',
        params: data
    })
}

export function listPriceDetail(data) {
    return request({
        url: 'charge-account/purchase/price-product',
        method: 'get',
        params: data
    })
}

export function onComplete(data) {
    return request({
        url: 'charge-account/purchase/complete',
        method: 'post',
        data: data
    })
}

export function getCarriage(purchasePlanUuid) {
    return request({
        url: 'charge-account/purchase/get-carriage/' + purchasePlanUuid,
        method: 'get'
    })
}

export function deleteCarriage(uuid) {
    return request({
        url: 'charge-account/purchase/carriage/' + uuid,
        method: 'delete'
    })
}

export function getPay(purchasePlanUuid) {
    return request({
        url: 'charge-account/purchase/factory/get-pay/' + purchasePlanUuid,
        method: 'get'
    })
}

export function deletePay(uuid) {
    return request({
        url: 'charge-account/purchase/factory/pay/' + uuid,
        method: 'delete'
    })
}
